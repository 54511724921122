
import { verifyMobilePhone, dealProtectionToken, dealSms, setAuthInfo, getUrlQuery } from "@/js/common/tool"
import { enterpriseAccountPassword, enterpriseAccountCaptchaCode } from './api'
import { captchaModalVerify } from './dealDom'
import { FINANICALWEB, PERSONALWEB, REIMBURSEMENT, BILLCENTERWEB } from "./constant";
const isPc = getUrlQuery('isPc')
const platform = getUrlQuery('platform') //enterprise电子报销进入
export default () => {
  const loginModal = $('body').on("#loginBox")
  let time = 60 // 获取验证码倒计时
  let codeTimer = ''
  /* 切换登录方式 */
  loginModal.on('click', '.tab-nav a', function () {
    const type = $(this).data('type')
    $(this).addClass('active').parent().siblings().children().removeClass('active')
    loginModal.find('.login-type').removeClass('active')
    $(`#login-${type}`).addClass('active')
  })

  /* 打开 关闭登录框 */
  loginModal.on('click', '.close-btn', function () {
    $('#loginModal').modal('hide')
  })
  loginModal.on('click', '.show-login', function () {
    $('#loginModal').modal('show')
  })
  loginModal.on('hidden.bs.modal', function (e) {
    loginModal.find('.password-input,.code-input').val('')
    showErr('')
    clearInterval(codeTimer);
    $('.getCode').attr('disabled', false).text('获取验证码')
  })

  /* 获取验证码 */
  loginModal.on("click", '.getCode', async function () {
    const telephone = $('#login-tel .tel-input').val()
    const verify = verifyMobilePhone(telephone)
    if (verify) {
      $.$msg(verify, 'alert-danger')
      return
    }
    const smsReqdata = {
      Account: telephone,
      FlagType: 0
    }
    $(this).attr('disabled', true)
    const smsRes = await dealSms(smsReqdata)
    if (smsRes.ResCode !== 1000) {
      if (smsRes.ResCode === 1008) { // 验证码频繁进行图形验证
        captchaModalVerify(smsRes)
      }
      $(this).attr('disabled', false)
      $.$msg(smsRes.Msg)
      return
    }
    let codeTime = time;
    const self = $(this)
    self.text(`${codeTime}秒后重新获取`)
    codeTimer = setInterval(() => {
      if (codeTime <= 0) {
        clearInterval(codeTimer);
        self.text(`获取验证码`).attr('disabled', false)
        return
      }
      codeTime -= 1
      self.text(`${codeTime}秒后重新获取`)
    }, 1000);

  })


  /* 密码可见 */
  loginModal.on("click", "#login-password .show-password", function () {
    const passwordInput = $('#login-password .password-input')
    if ($(this).hasClass('icon-bukejian')) {
      $(this).addClass("icon-kejian").removeClass('icon-bukejian')
      passwordInput.attr('type', 'text')
    } else {
      $(this).addClass("icon-bukejian").removeClass('icon-kejian')
      passwordInput.attr('type', 'password')
    }
  })

  /* 提交登录 */
  loginModal.on("click", ".submit", submitLogin)

  loginModal.on('input', '.tab-body input', function () {
    showErr('')
  })
  $('#login-box').on('keydown', function (e) {
    if (e.code === "Enter") {
      submitLogin()
    }
  })
  loginModal.on('click', '.switch-btn', function () {
    const swichLoginBox = $(this).parent('.switch-login')
    if (swichLoginBox.hasClass('pc')) {
      swichLoginBox.removeClass('pc').addClass('qr')
      $('.qr-section').show()
      $('.pc-section').hide()
    } else if (swichLoginBox.hasClass('qr')) {
      swichLoginBox.removeClass('qr').addClass('pc')
      $('.pc-section').show()
      $('.qr-section').hide()
    }
  })

  async function submitLogin() {
    const loginType = loginModal.find('.tab-nav a.active').data('type')
    let loginConfig = {};
    if (loginType === 'password') {
      loginConfig = {
        MobilePhone: loginModal.find('.tel-input').val(),
        Password: loginModal.find('.password-input').val()
      }
    } else {
      loginConfig = {
        MobilePhone: loginModal.find('.tel-input').val(),
        CaptchaCode: loginModal.find('.code-input').val(),
        LoginType: 1
      }
    }
    const verifiLoginResult = verifiLogin(loginConfig)
    if (verifiLoginResult) {
      showErr(verifiLoginResult)
    } else {
      let platformPage = $.$platform()
      console.log('loginConfig', loginConfig, platformPage)
      if (platformPage === 'agency') {
        loginConfig.IsAgency = true
      }
      const res = loginType === 'password' ? await enterpriseAccountPassword(loginConfig) : await enterpriseAccountCaptchaCode(loginConfig)
      if (res.ResCode === 1000) {
        let localStorageInfo = {
          CurrentUserId: res.User.UserId,
          CurrentUserName: res.User.UserName,
          Token: res.Token
        }
        let jumpLink = ''
        let fistBindEnterprises = false // 为true表示从BindEnterprises第一个取数据
        let errMsg = ''
        switch (platformPage) {
          case 'agency': // 财税机构登录
            fistBindEnterprises = true
            jumpLink = `${BILLCENTERWEB}`
            errMsg = '您还未注册财税机构账号'
            break;
          case 'financial': // 企业票夹登录
            jumpLink = `${FINANICALWEB}${isPc ? `?isPc=${isPc}` : ''}`
            errMsg = '您还未注册企业票夹账号'
            if (platform === 'enterprise') { // 电子报销
              fistBindEnterprises = true
              jumpLink = `${REIMBURSEMENT}`
            }
            break;
          case 'enterprise':
            errMsg = '您还未注册电子报销账号'
            jumpLink = `${REIMBURSEMENT}`
            break
          default: // 个人端
            jumpLink = `${PERSONALWEB}`
            break;
        }
        if (fistBindEnterprises && res.BindEnterprises.length) {
          localStorageInfo.CurrentUserName = res.BindEnterprises[0].CurrentUserName
          localStorageInfo.EnterpriseId = res.BindEnterprises[0].Id
        }
        setAuthInfo(localStorageInfo)
        const cb = getUrlQuery('cb')
        if (cb) {
          window.location.replace(decodeURIComponent(cb))
          return
        }
        if (platformPage !== 'personal' && !res.BindEnterprises.length) {
          $.$msg(errMsg)
          setTimeout(() => {
            window.location.replace(`./register.html?type=0&platform=${platformPage}`)
          }, 1500)
          return
        }
        setTimeout(() => {
          window.location.href = jumpLink
        }, 500)
      } else {
        showErr(res.Msg)
      }
    }
  }


  // 展示错误信息
  function showErr(err) {
    const inputErr = loginModal.find('.input-err')
    if (err) {
      inputErr.addClass('visible').removeClass('invisible').children('.err-text').text(err)
    } else {
      inputErr.addClass('invisible').removeClass('visible').children('.err-text').text(err)
    }
  }

  /* 验证登录信息 */
  function verifiLogin(data) {
    if (verifyMobilePhone(data.MobilePhone)) {
      return verifyMobilePhone(data.MobilePhone)
    }
    if (data.hasOwnProperty('Password') && !data.Password) {
      return "请输入正确的密码"
    }
    if (data.hasOwnProperty('CaptchaCode') && !data.CaptchaCode) {
      return "请输入正确的验证码"
    }
    return false
  }

  /* 注册按钮 */
  $('.psb-register-btn').on("click", function () {
    let param = {
      type: 0,
      platform: $.$platform()
    }
    let query = ''
    const queryArr = []
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryArr.push(`${key}=${param[key]}`)
      }
    }
    query = queryArr.join('&')
    window.location.href = `./register.html?${query}`
  })

  /* 退出按钮 */
  $('.psb-logout-btn').on("click", function () {
    $.$logout()
  })

  /* 忘记密码 */
  $('.psb-resetPwd-btn').on("click", function () {
    window.location.href = `./register.html?type=1`
  })

}