import ajax from "./ajax"
/*
params:{
customUrl:'https:www.baidu.com', 自定义请求地址
}
*/

export const getClientConfig = async (data) => {
  return await ajax({
    url: '/Config/GetClientConfig',
    data
  })
}
export const getProtectionToken = async () => {
  return await ajax({
    url: '/ApiProtection/GetProtectionToken',
    loading: false
  })
}
export const searchInvoiceTitles = async data => {
  return await ajax({
    url: '/InvoiceTitle/SearchInvoiceTitles',
    data,
    loading: false
  })
}
export const getPackage = async () => {
  return await ajax({
    url: '/web/enterpriseFolder_msi/package.json',
    type: 'get'
  })
}
export const getAdminEnterprises = async data => {
  return await ajax({
    url: '/Enterprise/GetAdminEnterprises',
    data
  })
}
export const getMemberDetail = async () => {
  return await ajax({
    url: '/Ticket/GetMemberDetail'
  })
}
// 短信
export const sendSms = async data => {
  return await ajax({
    url: '/Sms/SendSms',
    data
  })
}
// 前置接口
export const ticketInvoice = async data => {
  return await ajax({
    url: '/Ticket/Invoice',
    data
  })
}
// 注册
export const register = async data => {
  return await ajax({
    url: '/Ticket/Register',
    data
  })
}
// 单位注册
export const registerEnterprise = async data => {
  return await ajax({
    url: '/EnterpriseAccount/RegisterEnterprise',
    data
  })
}
// 找回密码
export const resetPassword = async data => {
  return await ajax({
    url: '/Ticket/ResetPassword',
    data
  })
}
// 更新图形码
export const createImageCaptcha = async data => {
  return await ajax({
    url: '/Captcha/CreateImageCaptcha',
    data
  })
}
// 企业账号密码登录
export const enterpriseAccountPassword = async data => {
  return await ajax({
    url: '/EnterpriseAccount/PasswordLogin',
    data
  })
}
// 企业账号验证码登录
export const enterpriseAccountCaptchaCode = async data => {
  return await ajax({
    url: '/EnterpriseAccount/CaptchaCodeLogin',
    data
  })
}
// 已经登录过的用户注册企业
export const createEnterpriseFromJson = async data => {
  return await ajax({
    url: '/Enterprise/CreateEnterpriseFromJson',
    data
  })
}
// 添加企业客户关系
export const createEnterpriseCustomer = async data => {
  return await ajax({
    url: '/Enterprise/CreateEnterpriseCustomer',
    data
  })
}
// 退出
export const logout = async data => {
  return await ajax({
    url: '/Ticket/Logout',
    data
  })
}
// 退出
export const getCompanyConfig = async () => {
  return await ajax({
    url: '/web/psbThirdConfig.json',
    type: 'get'
  })
}
// 上传文件
export const openUploadFile = async (data) => {
  return await ajax({
    url: '/FileCenter/OpenUploadFile',
    data,
    uploadFile: true
  })
}
// 解析营业执照
export const openPargeLicenseValidation = async (data) => {
  return await ajax({
    url: '/Enterprise/OpenPargeLicenseValidation',
    data
  })
}
// 获取购买套餐
export const getCostDisplay = async (data) => {
  return await ajax({
    url: '/CostPackage/GetCostDisplay',
    data
  })
}
// Web开通套餐
export const webOpenUserPackage = async (data) => {
  return await ajax({
    url: '/CostPackage/WebOpenUserPackage',
    data
  })
}